var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import moment from "moment";
export function toApiDate(date) {
    return date.format("YYYY-MM-DD");
}
export function fromApiDate(date) {
    if (!date) {
        return undefined;
    }
    return moment(date);
}
export function toApiPersonalInformation(_a) {
    var dateOfBirth = _a.dateOfBirth, estimatedArrivalInAustralia = _a.estimatedArrivalInAustralia, passportExpiry = _a.passportExpiry, input = __rest(_a, ["dateOfBirth", "estimatedArrivalInAustralia", "passportExpiry"]);
    return __assign({ dateOfBirth: dateOfBirth ? toApiDate(dateOfBirth) : undefined, passportExpiry: passportExpiry ? toApiDate(passportExpiry) : undefined, estimatedArrivalInAustralia: estimatedArrivalInAustralia
            ? toApiDate(estimatedArrivalInAustralia)
            : undefined }, input);
}
