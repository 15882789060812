var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { createEmptyAddress, SHIPPING_COUNTRIES_OPTIONS, } from "../../data";
import upperFirst from "lodash/upperFirst";
export default Vue.extend({
    props: {
        value: {
            type: Object,
            default: createEmptyAddress,
        },
        status: {
            type: Object,
            required: true,
        },
        namePrefix: {
            type: String,
            default: undefined,
        },
        validator: {
            type: Object,
            required: true,
        },
        width: {
            type: String,
            default: "full",
            validator: function (value) {
                return ["full", "half"].indexOf(value) >= 0;
            },
        },
    },
    data: function () {
        return {
            countryOptions: SHIPPING_COUNTRIES_OPTIONS,
        };
    },
    created: function () {
        this.$validator = this.validator;
    },
    methods: {
        fullName: function (name) {
            if (!this.namePrefix) {
                return name;
            }
            return this.namePrefix + upperFirst(name);
        },
        onInput: function (fieldName, value) {
            var _a;
            this.$emit("input", __assign(__assign({}, this.value), (_a = {}, _a[fieldName] = value, _a)));
        },
    },
});
