var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Card, createToken } from "vue-stripe-elements-plus";
import { mapState } from "vuex";
import { copyAddressWithoutGraphQlFields, createEmptyAddress, SHIPPING_COUNTRIES_OPTIONS, } from "../../../data";
import gql from "graphql-tag";
import AddressFieldSet from "../../dashboard/AddressFieldSet.vue";
import { obscuredCard } from "../../../utils/filters";
import find from "lodash/find";
import NotificationsMixin from "../NotificationsMixin";
import mixins from "vue-typed-mixins";
export default mixins(NotificationsMixin).extend({
    components: {
        Card: Card,
        AddressFieldSet: AddressFieldSet,
    },
    props: {
        viewerStudent: {
            type: Object,
            default: null,
        },
        buttonLabel: {
            type: String,
            required: true,
        },
        buttonWorkingLabel: {
            type: String,
            required: true,
        },
        status: {
            type: Object,
            required: true,
        },
        process: {
            type: Function,
            required: true,
        },
        billingName: {
            type: String,
            required: true,
        },
        shippingAddress: {
            type: Object,
            default: undefined,
        },
        width: {
            type: String,
            default: "full",
        },
        useBankTransfer: {
            type: Boolean,
            required: true,
        },
        onlyCreditCards: {
            type: Boolean,
            default: false,
        },
        disabledOnSuccess: {
            type: Boolean,
            default: false,
        },
        favourUseShippingAsBilling: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: String,
            default: undefined,
        },
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment BillingDetailsForm_viewerStudent on Student {\n        id\n        billingAddress {\n          streetAddress1\n          streetAddress2\n          city\n          postcode\n          state\n          country\n        }\n        stripePaymentSource {\n          id\n          last4\n          expMonth\n          expYear\n        }\n      }\n    "], ["\n      fragment BillingDetailsForm_viewerStudent on Student {\n        id\n        billingAddress {\n          streetAddress1\n          streetAddress2\n          city\n          postcode\n          state\n          country\n        }\n        stripePaymentSource {\n          id\n          last4\n          expMonth\n          expYear\n        }\n      }\n    "]))),
    },
    data: function () {
        var billingSameAsShipping = false;
        if (this.favourUseShippingAsBilling && this.shippingAddress) {
            billingSameAsShipping = true;
        }
        if (this.viewerStudent &&
            !this.viewerStudent.billingAddress &&
            this.shippingAddress) {
            billingSameAsShipping = true;
        }
        return {
            // Need to do this way so exclude __typename (or whatever other graphql or reactive stuff in obj)
            form: {
                paymentType: this.viewerStudent && this.viewerStudent.stripePaymentSource
                    ? "existing"
                    : "new-card",
                billingSameAsShipping: billingSameAsShipping ? "1" : "",
                billingAddress: this.viewerStudent && this.viewerStudent.billingAddress
                    ? copyAddressWithoutGraphQlFields(this.viewerStudent.billingAddress)
                    : createEmptyAddress(),
            },
        };
    },
    computed: __assign({ paymentRequiresTransformToAud: function () {
            return (["IDR", "CNY"].includes(this.currency) &&
                ["ali-pay", "we-chat-pay"].includes(this.form.paymentType));
        }, stripeOptions: function () {
            return {
                hidePostalCode: true,
                disabled: this.status.isWorking,
            };
        }, billingSameAsShippingOptions: function () {
            if (!this.shippingAddress) {
                return;
            }
            return [
                { value: "1", label: this.$t("Same as shipping address").toString() },
                {
                    value: "",
                    label: this.$t("Use a different billing address").toString(),
                },
            ];
        }, paymentTypeOptions: function () {
            var options = [
                {
                    value: "new-card",
                    label: "\n            ".concat(this.$t("New credit card").toString(), "\n            <img src=\"").concat(require("../../../images/visa.svg"), "\" alt=\"Visa\" />\n            <img src=\"").concat(require("../../../images/mastercard.svg"), "\" alt=\"Mastercard\" />\n            <img src=\"").concat(require("../../../images/amex.svg"), "\" alt=\"Amex\" />\n          "),
                },
            ];
            if (!this.onlyCreditCards) {
                options = __spreadArray(__spreadArray(__spreadArray([], options, true), (this.useBankTransfer
                    ? [
                        {
                            value: "bank-transfer",
                            label: this.$t("Bank transfer").toString(),
                        },
                    ]
                    : []), true), [
                    {
                        value: "ali-pay",
                        label: "".concat(this.$t("AliPay").toString(), " <img src=\"").concat(require("../../../images/alipay.svg"), "\" alt=\"Alipay\" />"),
                    },
                    {
                        value: "we-chat-pay",
                        label: "".concat(this.$t("WeChat Pay").toString(), " <img src=\"").concat(require("../../../images/wepay.svg"), "\" alt=\"WeChat Pay\" />"),
                    },
                ], false);
            }
            if (!this.viewerStudent || !this.viewerStudent.stripePaymentSource) {
                return options;
            }
            return __spreadArray([
                {
                    value: "existing",
                    label: this.$t("Existing credit card {cardNumber}", {
                        cardNumber: obscuredCard(this.viewerStudent.stripePaymentSource).toString(),
                    }).toString(),
                }
            ], options, true);
        } }, mapState(["stripePublishableKey"])),
    methods: {
        onSubmit: function () {
            var _this = this;
            this.status
                .run(this.$validator.validate().then(function (valid) {
                if (!valid) {
                    throw new Error(_this.$t("Please fix the errors shown").toString());
                }
                return _this.getBillingDetails().then(function (details) {
                    return _this.process(details)
                        .then(function () {
                        if (details.type === "new-card") {
                            _this.form.paymentType = "existing";
                        }
                    })
                        .catch(_this.displayPossibleApolloErrorMessage);
                });
            }))
                .catch(function (e) { return console.log(e); });
        },
        getBillingDetails: function () {
            var _this = this;
            if (this.form.paymentType === "existing") {
                return Promise.resolve({
                    type: "existing-card",
                    billingAddress: this.getBillingAddress(),
                });
            }
            if (this.form.paymentType === "new-card") {
                return createToken(this.createTokenOptions()).then(function (data) {
                    if ("error" in data) {
                        throw new Error(data.error.message);
                    }
                    return {
                        type: "new-card",
                        token: data.token.id,
                        billingAddress: _this.getBillingAddress(),
                    };
                });
            }
            return Promise.resolve({
                type: this.form.paymentType,
                billingAddress: this.getBillingAddress(),
            });
        },
        createTokenOptions: function () {
            var billingAddress = this.getBillingAddress();
            var stripeAddress = billingAddress === "same-as-shipping"
                ? this.shippingAddress
                : billingAddress;
            var countryOption = find(SHIPPING_COUNTRIES_OPTIONS, function (_a) {
                var value = _a.value;
                return value === stripeAddress.country;
            });
            return {
                name: this.billingName,
                address_line1: stripeAddress.streetAddress1,
                address_line2: stripeAddress.streetAddress2,
                address_city: stripeAddress.city,
                address_state: stripeAddress.state,
                address_zip: stripeAddress.postcode,
                address_country: countryOption
                    ? countryOption.label
                    : stripeAddress.country,
            };
        },
        getBillingAddress: function () {
            if (this.form.billingSameAsShipping) {
                return "same-as-shipping";
            }
            return this.form.billingAddress;
        },
    },
});
var templateObject_1;
